import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import {RegisterButton} from "../custom-components/button";
import CheckIcon from "@mui/icons-material/Check";

const HomeInfoSection = ({isMobile}) => {
  return (
    <Container sx={{ py: isMobile ? 0 : 6 }}>
      <Box sx={{ "padding": isMobile ? '0px' : "3rem 4rem" }} >
        <Grid container spacing={4} alignItems="center" sx={{ "margin": isMobile ? '0px' : { marginTop:'-32px', marginRight:'-32px'} }}>
          {/* Left Section */}
          <Grid item xs={12} md={6}   sx={{ pl: isMobile ? '1px !important': 'auto', pr: isMobile ? '1px !important' : 'auto' }}>
            <Typography  variant={isMobile? 'h6': 'h4'} fontWeight="bold" gutterBottom>
              Experience the future in Tech<br />
            </Typography>
            {/* <Typography variant="h5" fontWeight="bold" color="rgb(37, 211, 102)" mt={1}>
          Stipend upto ₹ 5,000 <Typography variant="body2" component="span"> /month</Typography>
          </Typography> */}
            <Box display="flex" alignItems="center" gap={1} mt={1} mb={1}>
              {/* <CheckIcon color="primary" sx={{ fontSize: 18 }} /> */}
              <Typography variant={isMobile? 'h6': 'h4'}>Learn,</Typography>
              <Typography variant={isMobile? 'h6': 'h4'}>Innovate,</Typography>
              <Typography variant={isMobile? 'h6': 'h4'}>Excel !</Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap={1} mt={1}>
              <CheckIcon color="primary" sx={{ fontSize: 18 }} />
              <Typography variant="h4">Learn</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <CheckIcon color="primary" sx={{ fontSize: 18 }} />
              <Typography variant="h4">Lead</Typography>
            </Box> */}
            <Typography variant="body1" color="textSecondary" mb={isMobile? 1:3} mr={isMobile? 2: 12}>
            Are you passionate learner eager to gain hands-on  real-world development experience in
            Full stack, Data Science, And DevOps development.
            </Typography>

            {/* <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ borderRadius: "8px", textTransform: "none", }}
          >
            <AppRegistrationIcon sx={{ color: "default", mr: 1 }} />
            Register
          </Button> */}
            {isMobile ? <></> : <RegisterButton text="register" ></RegisterButton> }   
          </Grid>

          {/* Right Section (Illustration) */}
          <Grid item xs={12} md={6}>
            <Box component="img"
              src="PSD-to-HTML5-CSS3.png"
              alt="Insurance Illustration"
              sx={{ width: "80%", maxWidth: 500, height: "80%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomeInfoSection;
