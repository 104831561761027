import React from "react";
import { Box, Typography, Grid, Paper, Container } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WebIcon from "@mui/icons-material/Web";
import CloudIcon from "@mui/icons-material/Cloud";
import AnalyticsIcon from '@mui/icons-material/Analytics';

const benefits = [
    "Full stack development",
    "Front end development,",
    "Mobile app development",
    "Data science solutions",
    "DevOps development"
    //   "Master modern frontend frameworks and libraries",
    //   "Gain hands-on experience with backend development",
    //   "Develop scalable and high-performance applications",
];

const cards = [
    {
        title: "Full Stack",
        description: "Build robust web applications with expertise in both frontend and backend technologies. Our full-stack solutions ensure seamless integration and high performance.",
        icon: <WebIcon sx={{ fontSize: 30, color: 'rgb(0 166 244)' }} />, // Replace with an image if needed
    },
    {
        title: "Data Science",
        description: "Data Science team analyzes complex datasets, develops machine learning models, and optimizes decision-making with AI-powered solutions",
        icon: <AnalyticsIcon sx={{ fontSize: 30, color:'rgb(0 166 244)' }} />,
    },
    {
        title: "DevOps",
        description: "Automate deployments, manage cloud infrastructure, and ensure seamless CI/CD pipelines for smooth software delivery. Our DevOps team optimizes workflows and minimizes downtime.",
        icon: <CloudIcon sx={{ fontSize: 30, color: 'rgb(0 166 244)' }} />,
    }
];

const WhatWeDo = () => {
    return (
        <Container sx={{ py: 6 }}>
            <Box sx={{ mt: 5 }}>
                <Grid container spacing={4} justifyContent="center">
                    {/* Left Section - Text Content */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                            What we do
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, mr: 6 }}>
                            We are a dynamic product development company focused on building and providing solutions.
                            While we do top-tier development with modern frameworks and libraries -
                        </Typography>

                        {/* Benefits List */}
                        {benefits.map((benefit, index) => (
                            <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 }}>
                                <CheckIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="body1">{benefit}</Typography>
                            </Box>
                        ))}
                    </Grid>

                    {/* Right Section - Info Cards */}
                    <Grid item xs={12} md={5}>
                        {cards.map((card, index) => (
                            <Paper
                                key={index}
                                elevation={3}
                                sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 2, mb: 2 }}
                            >
                                <Box sx={{ fontSize: 32, mr: 2 }}>{card.icon}</Box> {/* Replace with images if needed */}
                                <Box flexGrow={1}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {card.description}
                                    </Typography>
                                </Box>
                                {/* <ArrowForwardIosIcon fontSize="small" color="disabled" /> */}
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default WhatWeDo;
